import { createStore } from 'redux'
import AuthService from "./services/auth";

const initialState = {
  sidebarShow: 'responsive',
  user: AuthService.accessTokenToUser(localStorage.getItem('accessToken')),
  stores: null,
  settings: null,
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return {...state, ...rest }
    default:
      return state
  }
}

const store = createStore(changeState, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())
export default store