import request from '../request'

function login({email, password}) {
    return request({
        url: `/login`,
        method: 'POST',
        withCredentials: true,
        data: {
            email,
            password
        }
    });
}

function logout() {
    return request({
        url: '/logout',
        method: 'POST',
        withCredentials: true,
    });
}

function refreshToken() {
    return request({
        url: '/token',
        method: 'POST',
        withCredentials: true,
    });
}

const AuthAPI = {
    login, logout, refreshToken
}

export default AuthAPI;