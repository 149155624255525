import request from '../request'

function list(params) {
    return request({
        url: `/stores` + params,
        method: 'GET',
    });
}

function login(id) {
    return request({
        url: '/stores/' + id + '/login',
        method: 'POST',
        withCredentials: true,
    });
}

const StoreAPI = {
    list,
    login
}

export default StoreAPI;