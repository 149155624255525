import axios from 'axios'
import store from "../store";
import AuthService from "../services/auth";

const baseUrl = process.env.REACT_APP_API_HOST + '/internal'

const request = axios.create({
    baseURL: baseUrl,
});

let isRefreshingToken = false;
let requestsQueue = [];

request.interceptors.request.use(originalReq => {
    let state = store.getState();
    if (state.user && state.user.accessToken) {
        originalReq.headers.Authorization = "Bearer "  + state.user.accessToken
    }

    return originalReq
})

request.interceptors.response.use(response => {
    return response.data
}, error => {
    const {config: originalReq, response} = error;

    if (!originalReq.isRetryAttempt && response.status === 401) {
        originalReq.isRetryAttempt = true;
        if (!isRefreshingToken) {
            isRefreshingToken = true
            AuthService.refreshToken()
                .then(() => releaseRequestsQueue())
                .catch(() => clearRequestsQueue())
                .finally(() => {
                    isRefreshingToken = false;
                })
        }

        return new Promise((resolve, reject) => {
            requestsQueue.push(() => {
                resolve(request(originalReq));
            });
        });
    } else {
        return Promise.reject(error);
    }
})

function releaseRequestsQueue(){
    requestsQueue.map(cb => cb())
    clearRequestsQueue();
}

function clearRequestsQueue(){
    requestsQueue = []
}

export default request;