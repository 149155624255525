import React from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import ScrollToTop from "./components/ScrollToTop";
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-input-2/lib/style.css'
import 'react-calendar/dist/Calendar.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import "react-datepicker/dist/react-datepicker.css";
import './scss/style.scss';
import {useSelector} from "react-redux";

const loading = (
    <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"/>
    </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));

const App = () => {
    const user = useSelector(state => state.user)

    return (
        <Router>
            <React.Suspense fallback={loading}>
                <ScrollToTop/>
                <Switch>
                    <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>}/>
                    {user && user.accessToken ?
                        <Route path="/" name="Logged in store area" render={props => <TheLayout {...props}/>}/> :
                        <Redirect to="/login"/>
                    }
                </Switch>
            </React.Suspense>
        </Router>
    );
}

export default App;
