import AuthAPI from "../utils/api/auth";
import StoreAPI from "../utils/api/store";
import store from "../store";

function login({email, password}) {
    return AuthAPI.login({email, password}).then(function (data) {
        localStorage.setItem('accessToken', data.accessToken);
        store.dispatch({
            type: 'set',
            user: accessTokenToUser(data.accessToken)
        })
    });
}

function switchStore(id) {
    return StoreAPI.login(id).then(function (data) {
        localStorage.setItem('accessToken', data.accessToken);
        store.dispatch({
            type: 'set',
            user: accessTokenToUser(data.accessToken)
        })
    });
}

function logout() {
    localStorage.removeItem('accessToken')
    store.dispatch({type: 'set', user: null})
    store.dispatch({type: 'set', stores: null})
    store.dispatch({type: 'set', settings: null})

    AuthAPI.logout().catch((e) => console.log(e));
}

function refreshToken() {
    return AuthAPI.refreshToken().then(function (data) {
        localStorage.setItem('accessToken', data.accessToken);
        store.dispatch({
            type: 'set',
            user: accessTokenToUser(data.accessToken)
        })
    }).catch((error) => {
        logout()
        return Promise.reject(error);
    });
}

function parseJwt(token) {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

function accessTokenToUser(token) {
    if (!token) {
        return null
    }

    let payload = parseJwt(token)

    return {
        id: payload.sub,
        email: payload.email,
        name: payload.name,
        role: payload.role,
        storeId: payload.storeId,
        accessToken: token,
    }
}

const AuthService = {
    login, logout, refreshToken, accessTokenToUser, switchStore
}

export default AuthService;